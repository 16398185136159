import React, { useState } from "react"
import SEO from "../components/seo"
import styles from "./index.module.scss"
import BackgroundEffect from "../components/backgroundEffect"
import classNames from "classnames"
import "../styles/main.scss"
import { FullPage, Slide } from "react-full-page"

const IndexPage = () => {
  const [themeSelected, setThemeSelected] = useState(false)

  return (
    <React.Fragment>
      <FullPage>
        <Slide>
          <div
            className={classNames(styles.index, {
              [styles.index__white]: themeSelected,
              [styles.index__black]: !themeSelected,
            })}
            onClick={() => setThemeSelected(!themeSelected)}
          >
            <SEO title="Home" />
            <h1 className={styles.text_logo_principal}>55ONZE</h1>
            <div className={styles.bgCrazy}>
              <div>
                <span className={styles.txt_logo}> 55onze </span>
              </div>
              <div>
                <span className={styles.txt_logo}> 55onze </span>
              </div>
              <div>
                <span className={styles.txt_logo}> 55onze </span>
              </div>
              <div>
                <span className={styles.txt_logo}> 55onze </span>
              </div>
            </div>
            <div className={styles.scroll_downs}>
              <div className={styles.scroll_downs__mousey}></div>
            </div>
          </div>
        </Slide>
        <Slide>
          <div
            className={classNames(styles.about, {
              [styles.index__white]: themeSelected,
              [styles.index__black]: !themeSelected,
            })}
            onClick={() => setThemeSelected(!themeSelected)}
          >
            <div className={styles.left_container}>
              <h2>DE ONDE VEIO ESSA IDEIA?</h2>
              <p>
                Diante de um enorme déficit no mercado, a{" "}
                <span className={styles.txt_red}>55ONZE</span> surgiu na cidade
                de São Paulo por dois desenvolvedores web dispostos a oferecer
                um serviço justo e de qualidade.
              </p>
            </div>
            <BackgroundEffect side="right" />
          </div>
        </Slide>
        <Slide>
          <div
            className={classNames(styles.about, {
              [styles.index__white]: themeSelected,
              [styles.index__black]: !themeSelected,
            })}
            onClick={() => setThemeSelected(!themeSelected)}
          >
            <div className={styles.right_container}>
              <h2>AGILIDADE E PRECISÃO</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
                aliquet vehicula felis, non varius nibh congue tempor. Mauris
                tortor libero, vestibulum et vestibulum at, mattis in purus.
                Cras eget dapibus libero. Curabitur et blandit urna. Duis ac
                arcu molestie, volutpat leo ut, sodales sapien. Etiam diam enim,
                imperdiet et vehicula eu, blandit non ipsum. Aliquam elementum
                accumsan libero vel tristique. Morbi mauris urna, lobortis id
                eleifend vel, gravida tincidunt est. Cras ut turpis sagittis,
                mattis ex ac, suscipit odio.
              </p>
            </div>
            <BackgroundEffect side="left" />
          </div>
        </Slide>
        <Slide>
          <div
            className={classNames(styles.contact, {
              [styles.index__white]: themeSelected,
              [styles.index__black]: !themeSelected,
            })}
            onClick={() => setThemeSelected(!themeSelected)}
          >
            <div className={styles.bgCrazy_2}>
              <div>55onze</div>
              <div>55onze</div>
              <div>55onze</div>
              <div>55onze</div>
              <div>55onze</div>
            </div>
            <div className={styles.box_contact}>
              <h2>CONTATO</h2>
              <form method="POST" name="contact" data-netlify="true" action="/">
                <input type="hidden" name="form-name" value="contact" />
                <div>
                  <label htmlFor="name">Nome</label>
                  <input id="name" name="name" type="text" />
                </div>
                <div className={styles.formGroup}>
                  <div>
                    <label htmlFor="email">E-mail</label>
                    <input id="email" name="email" type="email" />
                  </div>
                  <div>
                    <label htmlFor="tel">Telefone</label>
                    <input id="tel" name="tel" type="tel" />
                  </div>
                </div>
                <div>
                  <label htmlFor="subject">Assunto</label>
                  <input id="subject" name="subject" type="text" />
                </div>
                <div>
                  <label htmlFor="message">Mensagem</label>
                  <textarea id="message" name="message"></textarea>
                </div>
                <button type="submit">Enviar</button>
              </form>
            </div>
          </div>
        </Slide>
      </FullPage>
    </React.Fragment>
  )
}

export default IndexPage
