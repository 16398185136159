import React from 'react';
import styles from './backgroundEffect.module.scss';

const BackgroundEffect = ({side}) => {
    return (
        <React.Fragment>
            <svg viewBox="0 0 139 70" className={styles[side]}>
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <polygon id="Path" fill="#000000" points="34.8220895 0 0 33.9727479 34.8220895 33.9727479 68.7116947 69.4714627 104.852124 69.4714627 138.596574 34.7357314 103.486012 34.7357314 69.2982871 0">
                    </polygon>
                </g>
            </svg>
        </React.Fragment>
    );
}

export default BackgroundEffect;